<template>
    <div class="jh-container">
        <div class="jh-ui-header">
            <h1>링크관리</h1>
            <div class="is-right">
            </div>
        </div>
        <div class="jh-search-form">
            <table>
                <colgroup>
                    <col width="65px">
                    <col width="200px">
                    <col width="80px">
                    <col width="100px">
                    <col>
                </colgroup>
                <tr>
                    <th><label>링크명</label></th>
                    <td>                        
                        <input type="text" class="jh-form" @keyup.enter="getListOrNot(linkNm)" v-model="linkNm">
                    </td>
                    <th><label>사용여부</label></th>
                    <td>
                        <v-select class="jh-form" :items="mixin_common_code_get(this.common_code, 'TWB001', '선택')" v-model="useYn"></v-select>
                    </td>
                    <td class="has-search" @click="getListOrNot"><v-btn class="jh-btn is-search">조회</v-btn></td>
                </tr>
            </table>
        </div>

        <div class="jh-form-wrap">
            <div class="jh-ui-header">
                <h2>링크 목록</h2>
                <div class="is-right">
                    <v-btn class="jh-btn is-border-blue" @click="registNew" >신규</v-btn>
                    <v-btn class="jh-btn is-border-blue" @click="newUntactUrl" v-if="this.mixin_set_btn(this.$options.name, 'btnDetail')">상세</v-btn>
                    <v-btn class="jh-btn is-del" @click="submitDeleteConfirm"  :disabled="this.detailData.index < 3? true : false">삭제</v-btn>
                </div>
            </div>
            <v-data-table
                class="jh-grid has-control"
                height="600px"
                :headers="divGrid01"
                :items="divGrid01DataText"
                :items-per-page="30"
                item-key="id"
                :page.sync="divGrid01Page"
                :item-class="isActiveRow"
                fixed-header
                hide-default-footer
                @page-count="divGrid01PageCount = $event"
                @click:row="getListSel"
                @dblclick:row="getGroupCdView"
                v-model="selectedDivGrid01List"
                no-data-text="검색된 결과가 없습니다."
            >
            </v-data-table>
            <div class="jh-pager">
                <v-pagination v-model="divGrid01Page" :length="divGrid01PageCount" :total-visible="divGrid01TotalVisible"></v-pagination>
                <span class="jh-counter is-left">총 <em>{{ divGrid01DataText.length }}</em> 건</span>
            </div> 
            
            <!--신규 그리드-->
            <!-- <data-tables
            dataTableClass="has-control"
            :data-table-options="dataTableOptions"
            @click:row="getListSel"
            @dblclick:row="getGroupCdView"
            :paginationOptions="paginationOptions"
            ></data-tables> -->
        </div>
    
        <v-dialog max-width="600" v-if="dialogE020614P01" v-model="dialogE020614P01" persistent>
            <dialog-E020614P01
                :param ="E020614P01Param"
                @hide="hideDialog('E020614P01')"
                @hideAndGetList="hideAndGetList('E020614P01')"
            >
            </dialog-E020614P01>
        </v-dialog>
        <v-dialog max-width="600" v-if="dialogE020614P02" v-model="dialogE020614P02" persistent>
            <dialog-E020614P02
                :param="E020614P02Param"
                @hide="hideDialog('E020614P02')"
                @hideAndGetList="hideAndGetList('E020614P02')"
            >
            </dialog-E020614P02>
        </v-dialog>
    </div>
</template>
<script>
import DialogE020614P02 from "./E020614P02.vue";
import DialogE020614P01 from "./E020614P01.vue";
import api from '../../store/apiUtil.js';
import {mixin} from "../../mixin/mixin.js";
export default {
  name: "MENU_E020604", //name은 'MENU_' + 파일명 조합
  mixins:[mixin], // mixin 등록
  components: {
    DialogE020614P01,
    DialogE020614P02,
  },
  data() {
    return {
      useYnDrop: [],
      common_code: [],
      HEADERResult:'',
      DATAResult:'',
      HEADER_SERVICE:'',
      HEADER_GRID_ID:'',
      headers:{},
      sendData: {},
      URLData:'',
      aspNewcustKey:'001',
      useYn:'',
      sysmsgType:'',
      msgClName:'',
      msgTypeName:'',
      msgDesc:'',
      msgTime:'',
      msgType:'',
      msgContent:'',
      msgCl:'',
      regId:'',
      regDttm:'',
      regDeptCd:'',
      updDeptCd:'',
      updDttm:'',
      updId:'',
      itProcessing:'',
      linksType: '',
      systemMsgId: '',
      sndrcvCd: '',
      procId: '',
      rowNumber:'',
      twbPagingTotCount:'',
      divgrid01Rownum:'',
      dialogE020614P01: false,
      dialogE020614P02: false,
      E020614P01Param:{},
      E020614P02Param:{},
      menu1: false,
      menu2: false,
      date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      dates: [
        (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
      ],
      dropItems: [],
      dropItems2 : [],
      dropItems3: [],
      divGrid01: [
        { text: "No", value: "index", align: "center", width: "50px" },
        { text: "링크명", value: "BTN_NM", align: "center", width: "200px" },
        { text: "링크 URL", value: "URL_MOBILE", align: "center",  width: "300px"},
        { text: "사용여부", value: "USE_YN", align: "center",  width: "150px"},
        { text: "등록자ID", value: "REG_ID", align: "center",  width: "150px"},
        { text: "등록일자", value: "REG_DTTM", align: "center",  width: "150px"},
      ],
      selectedDivGrid01List:[],
      selMsgClName:{},
      divGrid01DataText: [],
      divGrid01Page: 1,
      divGrid01PageCount: 0,
      divGrid01ItemsPerPage: 20,
      divGrid01TotalVisible: 50,
      detailData: {},

      linkNm : '',

    }
  },
  methods: {
    async init(){
      let codeName = ["TALK017","TWB001"];
      this.common_code= await this.mixin_common_code_get_all(codeName);
      this.useYnDrop = await this.mixin_common_code_get(this.common_code, 'TWB001', '전체')

      this.useYn = this.useYnDrop[1].value

      },
    startDate(e) {
      this.dates[0] = e;
    },
    endDate(e) {
      this.dates[1] = e;
    },
    getListOrNot(){
      this.getList();
    },
    getList(){
      this.headers = this.initHeaders;
      this.URLData = "/api/chat/setting/untactUrl/list";
      this.headers["SERVICE"] ="chat.setting.untactUrl";
      this.headers["METHOD"] = "list";
      this.headers["ASYNC"] = false;
      this.headers["ROW_CNT"] = "50";
      this.headers["PAGES_CNT"] = "1";
      this.headers["GRID_ID"] = "divGrid01";

      this.sendData= {};
      this.sendData["BTN_NM"] = this.linkNm;
      this.sendData["USE_YN"] = this.useYn;
      console.log(`getList! this.sendData : ${JSON.stringify(this.sendData)}`)
      this.result('S');
    },

    getDivGrid01Callback(response){
      let header = response.HEADER;
      let data = response.DATA;
      this.divGrid01DataText = data;
      this.selectedDivGrid01List = [];
      let idx = 1;
      let dataText = this.divGrid01DataText;
      for(let i in dataText){
        let id = dataText[i].NEW_ASP_CUST_KEY + "_" + dataText[i].UNTACT_ID;
        this.divGrid01DataText[i]["id"]= id;
        this.divGrid01DataText[i]["index"]= idx++;
      }
    },
    getListSel(item){
      // 선택한 아이템 저장
      this.selMsgClName = item;
      this.detailData = JSON.parse(JSON.stringify(item));

      console.log("item::");
      console.log(this.detailData);

    },
    isActiveRow(item){
      return item.id== this.detailData.id? 'is-active':'';
    },
    submitDeleteConfirm(){
      console.log("data:::");
      console.log(this.selectedDivGrid01List.length);
      console.log(this.selectedDivGrid01List);

      if(this.selectedDivGrid01List.length === 0){
        this.common_alert('체크된 항목이 존재하지 않습니다.', 'error')
        return;
      }
      this.common_confirm('삭제하시겠습니까?', this.submitDeleteGrid01, '', this.closeAlert, '', 'error')
    },

    submitDeleteGrid01(){
      this.headers = this.initHeaders;
      this.URLData = "/api/chat/setting/untactUrl/delete";
      this.headers["SERVICE"] = "chat.setting.untactUrl";
      this.headers["METHOD"] = "delete";
      this.headers["ASYNC"] = false;
      let deleteList = [];
      console.log("dataLength::: " + this.selectedDivGrid01List.length);
      for(let i in this.selectedDivGrid01List){
        let obj = {
          UNTACT_ID: this.selectedDivGrid01List[i].UNTACT_ID,
        };
        deleteList.push(obj);
      }
      console.log("deleteList:::");
      console.log(deleteList);
      this.sendData  = {
        list:deleteList
      }
      this.result();
    },
    submitDeleteGrid01CallBack(response){//
      this.common_alert('정상처리 되었습니다.', 'done')
      this.$emit("submitDelete", null);
      this.getList();

     // this.result()

    },
    closeAlert(){
      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: false,
      });
    },
    hideAndGetDetailList(type){
      this.hideDialog(type);
      this.selectedDivGrid01List = [];
      this.getList(this.selMsgClName);
    },
    registNew(){//신규
      if(this.aspNewcustKey == '' || this.aspNewcustKey == null){
        this.common_alert('회사구분을 선택해주세요.', 'error');
      }else{
        this.E020614P02Param = {
          headerTitle: "링크 신규등록",
          data: this.selMsgClName,
          type: "C",
          ASP_NEWCUST_KEY:this.aspNewcustKey,
        };
        this.showDialog('E020614P02');
      }

    },
    getGroupCdView(){//시스템메시지 상세 팝업 (수정)
      // 상세
      this.E020614P01Param = {
        headerTitle: "링크 상세",
        data: this.selMsgClName,
        type: "R",
        ASP_NEWCUST_KEY:this.aspNewcustKey,
      };
      this.showDialog('E020614P01');
    },
    hideAndGetList(type){
      this.selectedDivGrid01List = [];
      this.hideDialog(type);
      this.getList(this.selMsgClName);
    },
    showDialog(type) {
      this[`dialog${type}`] = true;
    },
    hideDialog(type) {
      this[`dialog${type}`] = false;
    },
    async result(requestData) {
      let head = '';
      let dataa = '';
      console.log(`call! result!!!`)
      //결과값을 보여주는 부분 -> 실제 사용 시 dataa의 value를 사용
      //api 호출 부분
      await api.post(this.URLData,   //api url입력
          //await axios.post('https://local.hkpalette.com:8443/'+this.URLData,{
          this.sendData
          , //api에서 사용될 data 입력
          {
            head: this.headers
          }) //api에 로그를 남기기위한 값입력 (필수) (SERVICE, METHOD, TYPE)
          .then((response) => {
            if(requestData === 'S'){
              if(!response.data.HEADER.ERROR_FLAG){
              } else {
                this.common_alert(response.data.HEADER.ERROR_MSG, 'error')
                return
              }
              console.log(`requestData : ${requestData} / `)
            } else {
              if(!response.data.HEADER.ERROR_FLAG){
              } else {
                this.common_alert(response.data.HEADER.ERROR_MSG, 'error')
                return
              }
            }
            head = JSON.stringify(response.data.HEADER);
            dataa = JSON.stringify(response.data.DATA);
            if (typeof (response.data) === "string") {
              let responseData = JSON.parse(response.data.replaceAll("\n", ""));
              this.$store.commit("alertStore/openAlertDialog", {
                alertDialogToggle: true,
                msg: responseData.HEADER.ERROR_MSG,
                iconClass: "svg-error-lg",
                type: "default",
              });
              return;
            }
            if (response.data.HEADER.METHOD === "list" ) {
              this.getDivGrid01Callback(response.data);
            }
            else if (response.data.HEADER.METHOD === "delete" || response.data.HEADER.METHOD === undefined) {
              this.submitDeleteGrid01CallBack(response.data)
            }
          }) //api 호출 성공 이후 수행
          .catch((err) => {
            alert(err);
          }) //api호출 에러 작업 수행
          this.HEADERResult = head;
          this.DATAResult = dataa;
    },
  },
  mounted(){
    this.init();
  },
  computed: {
    dateRangeText() {
      var newStartDate = this.dates[0];
      var newEndDate = this.dates[1];
      return newStartDate + ' ~ ' + newEndDate;
    },
    initHeaders() {
      return this.headers = {
        SERVICE: 'chat.setting.system-mssage-manage',
        METHOD: "",
        TYPE: 'BIZ_SERVICE',
      };
    },
  },
};
</script>

<style >

</style>